import React from 'react';

const dialNumber = {
    1: "",
    2: "ABC",
    3: "DEF",
    4: "GHI",
    5: "JKL",
    6: "MNO",
    7: "PQRS",
    8: "TUV",
    9: "WXYZ",
    '*': "",
    '0': "+",
    '#': ""
}

function DialPad(props) {

    const { phoneNumber, setPhoneNumber } = props;

    // Handle number input
    const handleKeyClick = (key) => {

        // Only 10 digits
        if (phoneNumber.length >= 10) {
            return;
        }

        // Add the new entry to previous number
        setPhoneNumber((prevPhoneNumber) => prevPhoneNumber + key);
    }

    // Define the order of keys to display in the grid
    const gridKeysOrder = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];

    return (
        <>
            <div className="grid grid-cols-3 gap-4">
                {gridKeysOrder.map((key) => (
                    <div
                        key={key}
                        className="flex flex-col items-center border p-2 rounded-lg cursor-pointer active:bg-[#ebebeb]"
                        onClick={() => handleKeyClick(key)}
                    >
                        <p>{key}</p>
                        <p>{dialNumber[key]}</p>
                    </div>
                ))}
            </div>
        </>
    )
}

export default DialPad