import React, { useEffect, useState } from 'react';

const CallTimer = (props) => {

    const { isCalling } = props;

    const [callTime, setCallTime] = useState(0);

    // Format time
    const formattedCallInSeconds = `${String(Math.floor(callTime / 60)).padStart(2, '0')}:${String(callTime % 60).padStart(2, '0')}`;

    useEffect(() => {
        if (isCalling) {
            const intervalId = setInterval(() => {
                setCallTime((prevCallTime) => prevCallTime + 1);
            }, 1000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [isCalling]);

    return (
        <p>{formattedCallInSeconds}</p>
    );
};

export default CallTimer;
