import React, { useEffect, useState } from 'react';

const TimerComponent = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

    // Format time
    const formattedTime = currentTime.toLocaleTimeString(undefined, {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
        // second: 'numeric',
    });

    useEffect(() => {
        // Show current time
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <p>{formattedTime}</p>
    );
};

export default TimerComponent;