import React, { useEffect } from "react";
import { getVoiceToken } from '../helpers/twilio';

const { Device } = require("twilio-client");

function Voice(props) {

    const { phoneNumber, setPhoneNumber, setIsCallRinging, setIsCalling, showErrorInToast } = props;
    let device;

    useEffect(() => {

        if (typeof window !== 'undefined') {

            const fetchToken = async () => {
                const response = await getVoiceToken();
                return response;
            };

            fetchToken().then((response) => {

                try {

                    if (response && response.data.token) {

                        // Setup Twilio.Device
                        device = new Device(response.data.token, {
                            codecPreferences: ["opus", "pcmu"],
                            fakeLocalDTMF: true,
                            enableRingingState: true,
                        });

                        let callBtn = document.getElementById("call-button");
                        let hangupBtn = document.getElementById("button-hangup");

                        device.on("ready", function (device) {
                            console.log('Device is ready')
                            var params = {
                                To: `+91${phoneNumber}`,
                            };

                            // Hidden btn
                            callBtn.classList.add("hidden");
                            hangupBtn.classList.remove("hidden");

                            if (device) {
                                var outgoingConnection = device.connect(params);
                                outgoingConnection.on("ringing", function () {
                                    setIsCallRinging(true);
                                });

                                // Function to mute and unmute
                                document.getElementById("button-mute").onclick =
                                    function () {
                                        if (outgoingConnection) {
                                            const isMuted = outgoingConnection.isMuted();
                                            outgoingConnection.mute(!isMuted);
                                        }
                                    };
                            }
                        });

                        device.on("error", function (error) {
                            // console.log('Hello device is error')
                        });

                        device.on("connect", function (conn) {
                            // console.log('Hello device is connect')
                        });

                        device.on("disconnect", function (conn) {
                            console.log('Device is disconnected')
                            setIsCalling(false)
                            setPhoneNumber('')
                            callBtn.classList.remove("hidden");
                        });

                        // Bind button to hangup call
                        document.getElementById("button-hangup").onclick =
                            function () {
                                // console.log('disconnect button click')
                                if (device) {
                                    device.disconnectAll();
                                    setIsCalling(false)
                                    setPhoneNumber('')
                                    callBtn.classList.remove("hidden");
                                }
                            };

                    } else {
                        // console.log('Error response----------->', response.message);
                        showErrorInToast(response.message || 'Something Went Wrong')
                        setIsCalling(false)
                    }
                } catch (error) {
                    showErrorInToast(error?.message || 'Something Went Wrong')
                    setIsCalling(false)
                }

            }).catch(function (error) {
                showErrorInToast(error?.message || 'Something Went Wrong')
                setIsCalling(false)
            });;
        }
    }, []);
    return (
        <></>
    )
}

export default Voice