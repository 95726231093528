import React from "react";

const AdblockOverlay = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">AdBlocker Detected</h2>
        <p className="my-3">
          We've detected that you are using an AdBlocker.
          Please consider disabling it and refresh the page.
        </p>
        <button
          onClick={() => window.location.reload()}
          className="bg-green-500 text-white p-3 rounded-lg"
        >
          Refresh
        </button>
      </div>
    </div>
  );
};

export default AdblockOverlay;