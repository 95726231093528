import React, { useState, useEffect } from 'react';
import './App.css';

// Toast
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// AdBlocker
import { useDetectAdBlock } from "adblock-detect-react";

// Icons
import { FaWifi } from 'react-icons/fa';
import { CgBatteryFull } from 'react-icons/cg';
import { GiNetworkBars } from 'react-icons/gi';
import { BsFillMicFill, BsFillMicMuteFill } from 'react-icons/bs';
import { PiSpeakerHigh, PiSpeakerSlash } from 'react-icons/pi';
import { RiHistoryFill } from 'react-icons/ri';
import { TiBackspaceOutline } from 'react-icons/ti';
import { MdCall } from 'react-icons/md';
import { Puff } from 'react-loader-spinner'

// Components
import TimerComponent from './components/Timer';
import DialPad from './components/DialPad';
import Voice from './components/Voice';
import AdblockOverlay from "./components/AddBlocker";
import { playErrorAlert } from './helpers/alert'
import CallHistory from './components/CallHistory';
import CallTimer from './components/CallTimer';

function App() {

  const [isCalling, setIsCalling] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isCallRinging, setIsCallRinging] = useState(false);
  const [isMuteMic, setIsMuteMic] = useState(false);
  const [isMuteSpeaker, setIsMuteSpeaker] = useState(false);
  const [showAdblockOverlay, setShowAdblockOverlay] = useState(false);
  const [showCallHistory, setShowCallHistory] = useState(false);

  const handleMicButtonClick = () => {
    setIsMuteMic((prevIsMuteMic) => !prevIsMuteMic);
  };

  const handleSpeakerButtonClick = () => {
    setIsMuteSpeaker((prevIsMuteSpeaker) => !prevIsMuteSpeaker);
  };

  // Toast Message
  const showErrorInToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    playErrorAlert();
  }


  // Handle delete number for * Remove only before calling
  const handleBackspaceClick = () => {
    if (!isCalling) {
      setPhoneNumber((prevPhoneNumber) => prevPhoneNumber.slice(0, -1));
    }
  };


  const handleCalling = async () => {

    let text = prompt("What's your name?");
    if (text !== "kanhu044") {
      alert(`Sorry! you are not allowed to make a call`);
      return;
    }

    //if call history is on
    if (showCallHistory) {
      setShowCallHistory(false);
      return;
    }

    // Phone pattern
    let phonePattern = /^[6-9]\d{9}$/;

    //check phone number and show error
    if (!phonePattern.test(phoneNumber)) {
      showErrorInToast('Invalid phone number!');
      return;
    }

    setIsCalling(!isCalling);
  }

  const handleCallHistory = async () => {
    setShowCallHistory(!showCallHistory);
  }

  // Detect AdBlocker
  const adBlockDetected = useDetectAdBlock();
  useEffect(() => {

    // Change the page title
    document.title = 'Twilio Voice Call By Kahnu';

    if (adBlockDetected) {
      setShowAdblockOverlay(true);
    }
  }, [adBlockDetected]);

  return (
    <>
      {showAdblockOverlay ? (
        <AdblockOverlay />
      ) : (
        <>
          {/* Toast For Error Message */}
          < ToastContainer />

          <div className="lg:h-screen flex justify-center items-center">
            <div className="h-[664px] w-[360px] border rounded-lg p-4 shadow-md select-none">
              <div className='flex flex-col justify-between h-full'>

                {/* Header */}
                <div className='flex justify-between'>

                  <div className=''>
                    <TimerComponent />
                  </div>

                  <div className='flex items-center gap-2'>
                    <GiNetworkBars />
                    <FaWifi />
                    <CgBatteryFull />
                  </div>
                </div>

                {/* Dialer Section */}

                {!showCallHistory ? (
                  <div className='flex justify-center'>

                    <h1 className='text-3xl overflow-hidden'>
                      {phoneNumber ? (
                        <>
                          {phoneNumber}<span className={isCalling ? 'hidden' : "cursor-animation"}>|</span>
                        </>
                      )
                        : <span className={isCalling ? 'hidden' : "cursor-animation"}>|</span>}
                    </h1>
                  </div>
                ) : ''}


                <div className=''>
                  {!isCalling && !showCallHistory ? (
                    <DialPad
                      phoneNumber={phoneNumber}
                      setPhoneNumber={setPhoneNumber}
                    />
                  ) : showCallHistory ? (

                    <CallHistory />

                  ) : (
                    <>
                      <div className='w-full h-[312px] p-3 flex flex-col justify-around items-center'>

                        {/* Call in seconds */}
                        <CallTimer
                          isCalling={isCalling}
                        />

                        {/* Is mic mute */}
                        <span
                          className='bg-green-500 p-3 rounded-[1.5rem] cursor-pointer'
                          onClick={handleMicButtonClick}
                          id='button-mute'
                        >
                          {isMuteMic ? (
                            <BsFillMicMuteFill
                              size={40}
                              color='white'
                            />
                          ) : (
                            <BsFillMicFill
                              size={40}
                              color='white'
                            />
                          )}
                        </span>

                        {/* Is speaker mute */}
                        <span
                          className='bg-gray-300 p-3 rounded-[1.5rem] cursor-not-allowed'
                          // onClick={handleSpeakerButtonClick}
                          id='button-audio-mute'
                          title='Not Supported'
                        >
                          {isMuteSpeaker ? (
                            <PiSpeakerSlash
                              size={40}
                              color='white'
                            />
                          ) : (
                            <PiSpeakerHigh
                              size={40}
                              color='white'
                            />
                          )}
                        </span>
                      </div>

                      {/*Twilio Voice Call */}
                      <Voice
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        isCallRinging={isCallRinging}
                        setIsCallRinging={setIsCallRinging}
                        setIsCalling={setIsCalling}
                        showErrorInToast={showErrorInToast}
                      />
                    </>
                  )}
                </div>

                {/* Footer */}
                <div className='flex'>
                  <div className='w-full flex justify-between items-center'>

                    <span
                      className='cursor-pointer'
                      onClick={!isCalling ? handleCallHistory : null}
                    >
                      <RiHistoryFill
                        size={40} />
                    </span>

                    <span>
                      {/* Call Button */}
                      <button
                        id="call-button"
                        className={`bg-green-500 p-3 rounded-[1.5rem] cursor-pointer`}
                        onClick={!isCalling ? handleCalling : null}
                      >
                        {isCalling && !isCallRinging ? (
                          <Puff
                            height="40"
                            width="40"
                            radius={1}
                            color="white"
                            ariaLabel="puff-loading"
                            visible={true}
                          />
                        ) : (
                          <MdCall size={40} color='white' />
                        )}

                      </button>

                      {/* Hang Up Button */}
                      <button
                        id="button-hangup"
                        className={`${isCalling ? 'bg-red-500' : 'bg-green-500'}  p-3 rounded-[1.5rem] cursor-pointer hidden`}
                      >
                        <MdCall size={40} color='white' />
                      </button>

                    </span>

                    <span className={`rounded-full`} >
                      <TiBackspaceOutline className='cursor-pointer' size={40} onClick={handleBackspaceClick} />
                    </span>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default App;