import axios from "axios";

const backEndApiUrl = process.env.REACT_APP_API_URL || "https://twilio-api.developernoob.in/api/get-call-logs" ;

export const getVoiceToken = async () => {

    const url = `${backEndApiUrl}/api/get-voice-token`;
    const headers = {
        Authorization: 'bearer_token',
    };

    try {
        const tokenResponse = await axios.post(url, null, { headers });
        return {
            success: true,
            message: 'Token fetched successfully',
            data: tokenResponse?.data || [],
        };
    } catch (error) {
        return {
            success: false,
            message: 'Failed to get Token',
            data: [],
        };
    }
};

export const getAllCallHistory = async () => {

    const url = `${backEndApiUrl}/api/get-call-logs`;
    const headers = {
        Authorization: 'bearer_token',
    };

    try {
        const tokenResponse = await axios.get(url, null, { headers });
        return {
            success: true,
            message: 'Call history fetched successfully',
            data: tokenResponse?.data?.data || [],
        };
    } catch (error) {
        return {
            success: false,
            message: 'Failed to fetch call history',
            data: [],
        };
    }
};