import React, { useState, useEffect } from "react";
import { getAllCallHistory } from '../helpers/twilio';
import { Circles } from 'react-loader-spinner'

import { FaRegCheckCircle } from 'react-icons/fa';
import { FcEndCall } from 'react-icons/fc';
import { PiPhoneCall } from 'react-icons/pi';


// Format Date
function formatDate(originalDateString) {
    const originalDate = new Date(originalDateString);
    return originalDate.toLocaleDateString(undefined, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
}

// Format Time
function formatTime(originalDateString) {
    const originalDate = new Date(originalDateString);
    return originalDate.toLocaleTimeString(undefined, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hour: 'numeric',
        minute: '2-digit',
        // second: '2-digit',
        hour12: true
    });
}


function CallHistory() {


    const [isLoading, setIsLoading] = useState(true);
    const [callHistoryData, setCallHistoryData] = useState([]);

    useEffect(() => {

        const fetchCallHistory = async () => {
            const response = await getAllCallHistory();
            return response;
        };

        fetchCallHistory().then((response) => {

            console.log('All Call History', response);
            setIsLoading(false);
            setCallHistoryData(response.data);


        }).catch(function (error) {

            console.log('Error', error);

            // showErrorInToast(error?.message || 'Something Went Wrong')
            // setIsCalling(false)
        });

    }, []);



    return (
        <>
            <div className='w-full h-[500px] p-3 flex flex-col items-start break-all overflow-hidden hide-scrollbar'>

                <p className='text-xl'>Recent Calls</p>

                {isLoading ? (
                    <div
                        className="w-full h-full flex justify-center items-center"
                    >
                        <Circles
                            height="60"
                            width="60"
                            color="#4fa94d"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>

                ) : callHistoryData.length === 0 ? (
                    <div
                        className="w-full h-full flex justify-center items-center"
                    >
                        <p>No Call History Found</p>
                    </div>

                ) : (

                    <div className='w-full overflow-y-scroll'>

                        {callHistoryData.map((history, index) => (
                            <div key={index} className='py-2 flex justify-between items-center border-b-[1px] border-gray-500 text-gray-500 '>
                                <div>
                                    <p>{history.to}</p>
                                    <p className="flex items-center">
                                        <span className="mr-2">
                                            {history.status === 'busy' ?
                                                <PiPhoneCall
                                                    title="Busy"
                                                    color="red"
                                                /> :
                                                history.status === 'completed' ?
                                                    <FaRegCheckCircle
                                                        title="Completed"
                                                        color="green"
                                                    /> :
                                                    history.status === 'no-answer' ?
                                                        <FcEndCall
                                                            title="No Answer"
                                                        /> :
                                                        history.status === 'ringing' ?
                                                            <PiPhoneCall
                                                                title="Ringing"
                                                            />
                                                            : ''
                                            }
                                        </span>
                                        {Math.floor(history.duration / 60) ? Math.floor(history.duration / 60) + ' min ' : ''}
                                        {history.duration % 60 ? history.duration % 60 + ' sec' : ''}
                                    </p>
                                </div>

                                <div>
                                    <p>{formatDate(history.dateCreated)}</p>
                                    <p>{formatTime(history.dateCreated)}</p>
                                </div>

                            </div>
                        ))}
                    </div>
                )}
            </div >
        </>
    )
}

export default CallHistory